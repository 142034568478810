import { H, Section } from "@jfrk/react-heading-levels";
import { useIntl } from "gatsby-plugin-intl";
import React, { useState, useEffect } from "react";

import useDataApi from "../../hooks/data-api";
import { useHTMLProcessor } from "../../hooks/htmlProcessor";
import useSearchSettings from "../../hooks/searchSettings";
import { useURLParams } from "../../hooks/url-params";
import { getPostTypeNameSingular } from "../../utils/postTypes";
import FilterList from "../FilterList/FilterList";
import Link from "../Link";
import Pagination from "../Pagination/Pagination";
import SearchFormSiteSearch from "../SearchForm/SearchFormSiteSearch";
import SectionHeader from "../SectionHeader/SectionHeader";
import SortList from "../SortList/SortList";
import Time from "../Time";

import "../SearchResult/SearchResult.scss";
import "../SiteSearch/SiteSearch.scss";

function normalizePermalinkForPostType(postType, permalink) {
  if (permalink && postType === "attachment") {
    return process.env.GATSBY_WORDPRESS_URL + "-content/uploads/" + permalink;
  }
  return permalink;
}

const SORT_OPTIONS = {
  relevance: {
    name: "Relevans",
    sort: "_score",
    sort_order: "desc",
  },
  date: {
    name: "Datum",
    sort: "post_date",
    sort_order: "desc",
  },
  title: {
    name: "Titel",
    sort: "post_title",
    sort_order: "asc",
  },
};

export default function SiteSearch({ hitsPerPage = 10, ...restProps }) {
  const { processContent } = useHTMLProcessor();
  let [params, setParams] = useURLParams("/sok");
  let {
    query = "",
    post_type = false,
    sort: sortOption = "relevance",
    page = 0,
  } = params;
  page = Number(page);
  let [search, setSearch] = useState(query);
  let [{ data, isLoading, error }, setRequest] = useDataApi();

  useEffect(() => {
    setSearch(query);
  }, [query]);

  useEffect(() => {
    // Query variables:
    // query = false,
    // post_type = false, // facettering
    // from = 0,
    // size = 10,
    // sort = false,
    // sort_order = "asc"
    const { sort, sort_order } = SORT_OPTIONS[sortOption];
    setRequest({
      url: `/search/www`,
      data: {
        index: process.env.GATSBY_ELASTIC_INDEX,
        query,
        sort,
        post_type,
        sort_order,
        from: page * hitsPerPage,
        size: hitsPerPage,
      },
    });
  }, [query, page, post_type, sortOption]);

  const { hits, total = 0, total_all = 0, aggregations = {} } = data || {};

  const totalPages = Math.ceil(total / hitsPerPage);
  const {
    displaySettings: { emptySearchResultMessage },
  } = useSearchSettings();
  const intl = useIntl();

  return (
    <div {...restProps} className="c-search">
      <SearchFormSiteSearch
        className="o-margin-top-medium o-margin-bottom-medium"
        search={search}
        handleChange={(value) => {
          setSearch(value);
        }}
        handleSubmit={(event) => {
          event.preventDefault();
          setParams((params) => ({
            ...params,
            query: search,
            page: undefined,
          }));
        }}
        searchLabelText={intl.formatMessage({ id: "Search the website" })}
        searchButtonText={intl.formatMessage({ id: "Search" })}
      />
      {query && aggregations && aggregations.post_type ? (
        <FilterList
          postTypes={aggregations.post_type}
          totalHits={total_all}
          postType={post_type}
          query={query}
          onLinkClick={(event, post_type) => {
            event.preventDefault();
            setParams((params) => ({
              ...params,
              post_type,
              page: undefined,
            }));
          }}
        />
      ) : null}
      {total > 0 ? (
        <SortList
          options={SORT_OPTIONS}
          value={sortOption}
          onLinkClick={(event, sort) => {
            event.preventDefault();
            setParams((params) => ({
              ...params,
              sort,
              page: undefined,
            }));
          }}
        />
      ) : null}
      <Section>
        {!query ? (
          "Ange sökterm"
        ) : isLoading ? (
          "Laddar…"
        ) : error ? (
          `Ett fel inträffade: ${error.message}`
        ) : !hits || !hits.length ? (
          emptySearchResultMessage
        ) : (
          <>
            <SectionHeader
              className="u-visually-hidden"
              title={`Sökresultat för ${query}`}
              withBorder={true}
              description={
                <>
                  {`${total} träffar på `}”<em>{query}</em>”.
                </>
              }
            />
            <div className="c-list">
              <ul className="c-list c-list__search-result">
                {hits.map((hit, index) => {
                  return (
                    <li key={index} className="c-list__item">
                      <div className="c-list__header">
                        <H className="c-list__heading">
                          <Link
                            to={normalizePermalinkForPostType(
                              hit.post_type,
                              hit.permalink,
                            )}
                            className="c-list__title-heading-link"
                          >
                            {processContent(hit.post_title)}
                          </Link>
                        </H>
                      </div>
                      <div className="c-list__body">
                        <p>{processContent(hit.post_content)}</p>
                      </div>
                      <div className="c-list__meta">
                        <p className="c-list__type">
                          <span className="u-visually-hidden">
                            Typ av innehåll:
                          </span>
                          {getPostTypeNameSingular(hit.post_type)}
                        </p>
                        <Time
                          date={hit.post_date}
                          format={{
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                          }}
                        />
                        {hit.municipio_filesize_format && (
                          <p className="c-list__type">
                            <span className="u-visually-hidden">
                              Filstorlek:
                            </span>
                            {hit.municipio_filesize_format}
                          </p>
                        )}
                        {hit.post_mime_type && (
                          <p className="c-list__type">
                            <span className="u-visually-hidden">Filtyp:</span>
                            {hit.post_mime_type}
                          </p>
                        )}
                      </div>
                      <div className="c-list__footer" />
                    </li>
                  );
                })}
              </ul>
            </div>
            <Pagination
              page={page}
              totalPages={totalPages}
              onLinkClick={(event, page) => {
                event.stopPropagation();
                event.preventDefault();
                setParams((params) => ({ ...params, page }));
                window.scrollTo(0, 0);
              }}
            />
          </>
        )}
      </Section>
    </div>
  );
}
