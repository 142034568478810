import { useIntl } from "gatsby-plugin-intl";
import React from "react";

import Link from "../Link/Link";
import "./SortList.scss";

export default function SortList({
  value,
  options,
  onLinkClick,
  ...restProps
}) {
  const intl = useIntl();
  return (
    <div className="c-sort-list" {...restProps}>
      <p id="sortlistheadline" className="c-sort-list__headline">
        {intl.formatMessage({ id: "Sort by" })}:
      </p>
      <ul className="c-sort-list__list" aria-labelledby="sortlistheadline">
        {Object.entries(options).map(([key, { name }]) => {
          return (
            <li className="c-sort-list__item" key={key}>
              <Link
                queryParams={{ sort: key, page: undefined }}
                className="c-sort-list__link"
                aria-current={key === value}
                onClick={(event) => {
                  if (onLinkClick) {
                    onLinkClick(event, key);
                  }
                }}
              >
                {name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
