import { H } from "@jfrk/react-heading-levels";
import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import SiteSearch from "../components/SiteSearch/SiteSearch";
import { RekaiTrack } from "../hooks/rekai";

export default function SearchPage() {
  return (
    <Layout hideSearchForm={true}>
      <SEO title="Sök på webbplatsen" />
      <RekaiTrack />
      <article className="c-article o-margin-top-large">
        <div className="o-grid">
          <div className="o-grid-row">
            <div className="o-grid-block o-grid-block--inherit">
              <H className="c-article__title">Sök</H>
              <SiteSearch />
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
}
