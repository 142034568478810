import cx from "classnames";
import React, { useState, useRef } from "react";
import Autosuggest from "react-autosuggest";

import {
  getSuggestionValue,
  getSuggestions,
  getFoundSuggestions,
  renderInputComponent,
  renderSuggestions,
} from "../../hooks/autoSuggestions";
import useSearchSettings from "../../hooks/searchSettings";
import "./SearchForm.scss";

export default function SearchFormSiteSearch({
  search,
  handleChange,
  handleSubmit,
  searchLabelText,
  searchButtonText,
  className,
  ...restProps
}) {
  const {
    searchWord: { suggestions },
  } = useSearchSettings();

  const [foundSuggestions, setFoundSuggestions] = useState([]);

  let submitSiteSearchRef = useRef(null);
  let inputSiteSearchRef = useRef(null);

  const inputProps = {
    id: "search-form-query",
    name: "query",
    className: "c-search-form__field",
    type: "search",
    value: search,
    ref: inputSiteSearchRef,
    onChange: (event, { newValue }) => {
      handleChange(newValue);
    },
  };

  return (
    <form
      className={cx("c-search-form c-search-form--site-search", className)}
      onSubmit={handleSubmit}
      ref={submitSiteSearchRef}
      {...restProps}
    >
      <Autosuggest
        suggestions={foundSuggestions}
        renderInputComponent={(inputProps) =>
          renderInputComponent(
            searchLabelText,
            searchButtonText,
            "search-form__label",
            inputProps,
            search,
          )
        }
        renderSuggestion={(suggestion) => {
          return renderSuggestions(
            suggestion,
            search,
            submitSiteSearchRef,
            inputSiteSearchRef,
          );
        }}
        inputProps={inputProps}
        getSuggestionValue={getSuggestionValue}
        onSuggestionsFetchRequested={({ value }) => {
          setFoundSuggestions(
            getFoundSuggestions(value, getSuggestions(suggestions)),
          );
        }}
        onSuggestionsClearRequested={() => {
          setFoundSuggestions([]);
        }}
        shouldRenderSuggestions={(value) => {
          return value.trim().length > 1;
        }}
      />
    </form>
  );
}
