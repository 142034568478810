import { Section } from "@jfrk/react-heading-levels";
import { useIntl } from "gatsby-plugin-intl";
import React from "react";

import { getPostTypeNameSingular } from "../../utils/postTypes";
import Link from "../Link/Link";
import "./FilterList.scss";

export default function FilterList({
  postTypes,
  postType,
  totalHits,
  onLinkClick,
  ...restProps
}) {
  const postTypesList = [
    {
      key: "all",
      doc_count: totalHits,
    },
    ...(postTypes || []),
  ];
  const intl = useIntl();

  return (
    <div className="o-margin-bottom-small" {...restProps}>
      <Section>
        <p className="u-visually-hidden" id="filterlistheadline">
          {intl.formatMessage({ id: "Only show search results within" })}:
        </p>
        <ul className="c-filter-list" aria-labelledby="filterlistheadline">
          {postTypesList.map(({ key, doc_count }, index) => {
            if (key === "all") {
              return (
                <li className="c-filter-list__item" key={index}>
                  <Link
                    queryParams={{ post_type: undefined, page: undefined }}
                    className="c-button c-button--active c-button--tiny"
                    aria-current={!postType}
                    onClick={(event) => {
                      if (onLinkClick) {
                        onLinkClick(event, undefined);
                      }
                    }}
                  >
                    {getPostTypeNameSingular(key)}{" "}
                    {intl.formatMessage({ id: "hits" })}
                    <span className="c-filter-list__count">
                      &nbsp;({doc_count})
                      <span className="u-visually-hidden">
                        {" "}
                        {intl.formatMessage({ id: "hits" })}
                      </span>
                    </span>
                  </Link>
                </li>
              );
            } else {
              return (
                <li className="c-filter-list__item" key={index}>
                  <Link
                    queryParams={{ post_type: key, page: undefined }}
                    className="c-button c-button--active c-button--tiny"
                    aria-current={key === postType}
                    onClick={(event) => {
                      if (onLinkClick) {
                        onLinkClick(event, key);
                      }
                    }}
                  >
                    {getPostTypeNameSingular(key)}
                    <span className="c-filter-list__count">
                      &nbsp;({doc_count})
                      <span className="u-visually-hidden">
                        {" "}
                        {intl.formatMessage({ id: "hits" })}
                      </span>
                    </span>
                  </Link>
                </li>
              );
            }
          })}
        </ul>
      </Section>
    </div>
  );
}
